import 'core-js/es/promise'

if(window.fetch) {
    console.log('ES6')
    import('./Init').then(module => module.default())
} else {
    import('./polyfills').then(() => {
        console.log('Too old')

        import('./Init').then(module => module.default())
    })
}
